<template>
    <styled-interface
        class="dealer-dashboard"
        hide-toolbar
        fixed-container>
        <styled-card
            class="mt-5"
            hide-toggle>
            <template #heading>
                Custom Playbooks
            </template>
            <custom-table
                name="custom-playbooks"
                min-width="1140px"
                :items="playbooks"
                :fields="fields"
                :loading="loading"
                :notification="notification"
                :notification-type="notificationType"
                :rows-per-page="50"
                slim-cells />
        </styled-card>
    </styled-interface>
</template>

<script>
import StyledInterface from '@/components/globals/StyledInterface';
import StyledCard from '@/components/globals/StyledCard';
import CustomTable from '@/components/globals/CustomTable/index.vue';
import { mapState, mapGetters } from 'vuex';

export default {
    name: 'CustomPlaybooks',
    components: {
        StyledInterface,
        StyledCard,
        CustomTable,
    },
    title: 'Custom Playbooks',
    data() {
        return {
            loading: false,
            fields: [
                {
                    id: 'name',
                    header: 'Name',
                    value: 'display_name',
                    align: 'left',
                    width: '50%',
                    sortable: true,
                    type: 'single',
                },
                {
                    id: 'button',
                    header: '',
                    value: '',
                    align: 'right',
                    width: '50%',
                    type: 'component',
                    component: () => import('@/components/playbooks/DeployButton.vue'),
                },
            ],
            notification: '',
            notificationType: '',
            dealerPlays: [],
            playbooksRaw: [],
            hasInit: false
        };
    },
    computed: {
        ...mapState({
            currentDealerId: (state) => state.dealer.currentDealerId,
            currentAgency: (state) => state.agency.currentAgency
        }),
        ...mapGetters([
            'activeDealerOnboardings'
        ]),
        playbooks() {
            const formatPlaybook = (playbook) => {
                playbook.order = 0;
                playbook.onboarding = false;
                playbook.deployed = false;
                playbook.error = null;

                const existingOnboarding = this.activeDealerOnboardings.find(onboarding => onboarding.play_id === playbook.plays?.[0]?.id);
                if (existingOnboarding) {
                    playbook.onboarding = existingOnboarding.id;
                }

                else if (this.dealerPlays.some(dealerPlay => dealerPlay.play_id === playbook.plays?.[0]?.id)) {
                    playbook.deployed = true;
                    playbook.error = 'Currently Deployed';
                }

                return playbook;
            };

            return this.playbooksRaw.map(formatPlaybook);
        }
    },
    created() {
        this.init();
    },
    activated() {
        // Refresh plays if we've already initialized
        if (this.hasInit) {
            this.getDealerPlays();
        }
    },
    methods: {
        async init() {
            this.loading = true;
            await Promise.all([
                this.getCustomPlaybooks(),
                this.getDealerPlays()
            ]);
            this.loading = false;
            this.hasInit = true;
        },
        async getCustomPlaybooks() {
            try {
                const response = await this.$apiRepository.getCustomPlaybooks();
                this.playbooksRaw = response.data.data;
            } catch (error) {
                console.error('Error loading custom plays', error);
                this.notification = error + '!';
                this.notificationType = 'error';
            }
        },
        async getDealerPlays() {
            try {
                const response = await this.$apiRepository.getDealerPlays(this.currentDealerId);
                this.dealerPlays = response.data.data;
            } catch (error) {
                console.error('Error loading dealer plays', error);
                this.notification = error + '!';
                this.notificationType = 'error';
            }
        }
    }
};
</script>
